<template>
  <div>
    <b-card class="border">
      <!-- <b-card-body>
        <b-card-title>الصلاحيات</b-card-title>
        <b-card-sub-title>الصلاحيات حسب الأدوار</b-card-sub-title>
        
        <form-input/>
      </b-card-body> -->
      <b-table
        v-on="$listeners"
        ref="dataTable"
        :fields="fields"
        :items="myProvider || itemsLocal"
        :current-page="perPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :busy.sync="loading"
        :api-url="ep"
        empty-filtered-text="لا يوجد نتائج تطابق البحث"
        empty-text="لا يوجد سجلات لعرضها"
        no-local-sorting
        show-empty
        responsive
        v-bind="$attrs"
      >
        <template #table-busy>
          <div>
            <b-skeleton-table
              :rows="5"
              :columns="fields.length || 4"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </div>
        </template>
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data"></slot>
        </template>
      </b-table>
      <div class="my-1" v-if="pagination">
        <b-row>
          <b-col
            lg="5"
            sm="12"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            عدد المدخلات في الصفحة
            <b-form-select
              style="width: 50%"
              v-model="perPage"
              :options="pageOptions"
              class="mx-1"
            />
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            lg="7"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BTable,
  BSkeletonTable,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BCard,
  BCardBody,
    BCardTitle,
    BCardSubTitle
} from "bootstrap-vue";
import { get } from "lodash";
import { debounce } from "lodash";

export default {
  components: {
    BTable,
    BSkeletonTable,
    BRow,
    BCol,
    BFormSelect,
    BPagination,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle
  },
  props: {
    id: { type: String, default: () => Math.random().toString() },
    ep: { type: String, default: () => null },
    fields: { type: Array, default: () => [] },
    pagination: { type: Boolean, default: () => true },
    dataPath: {
      type: String,
      default: () => "data",
    },
    filter:{type:String , default:()=> ""},
    utils:{
      type:Object ,default:()=>{{}}
    },
    itemsLocal:{type:Array ,default: () => [] },  
  },

  data() {
    return {
      pageOptions: [10, 15, 20, 25],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
    };
  },


  methods: {
    refreshTable() {
      this.$refs.dataTable.refresh();
    },
    myProvider(ctx, callback) {
      this.loading = true;
      if (this.item) {
        callback(this.items);
      } else {
        this.$axios
          .get(this.ep, {
            params: {
              page: this.currentPage,
              per_page: this.perPage,
              filter:this.filter,
              sortBy: ctx.sortBy,
              sortDesc: ctx.sortDesc,
              ...this.utils
            },
          })
          .then((res) => {
            if (this.pagination) {
              this.totalRows = res.data.pagination
                ? res.data.pagination.total_row
                : res.data.data.length;
            }
            const resolved = get(res.data, this.dataPath);
            callback(resolved);
          })
          .catch((err) => {
            console.log(err);
            callback([]);
          });
      }
      this.loading = false;
      return null;
    },
  },
  watch:{
    filter: debounce(function (value) {
      this.currentPage = 1;
      this.refreshTable();
    }, 700),
    currentPage(val) {
      this.refreshTable();
    },
  }
};
</script>